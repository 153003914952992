import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { GlobalBreadcrumbComponent } from "src/app/global/breadcrumb-component/breadcrumb-component";
import { GlobalSkeletonFormComponent } from "src/app/global/skeleton-form-component/skeleton-form-component";
import { GlobalHamburgerMenuComponent } from "src/app/global/hamburger-menu/hamburger-menu-component";
import { gitDatepicker } from "src/app/global/git-datepicker/git-datepicker";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";

import { GlobalAutoNumericComponent } from "src/app/global/autonumeric-component/autonumeric-component";
import { ConfirmLeaveComponent } from "src/app/global/confirm-leave-component/confirm-leave-component";
import { NgSelectGlobal } from "src/app/global/ng-select-global/ng-select-global";
import { TimelineGlobal } from "src/app/global/timeline/timeline";
import { HttpLoaderImageComponent } from "src/app/global/http-loader-image/http-loader-image.component";
import { TalentMatrixGlobal } from "src/app/global/talent-matrix/talent-matrix";

import { DragDropDirectiveDirective } from "./drag-drop-directive.directive";
import { ModalModule } from "ngx-bootstrap/modal";
import { NgSelectModule } from "@ng-select/ng-select";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { TimepickerModule } from "ngx-bootstrap/timepicker";
import { gitBtnExport } from "./git-btn-export/git-btn-export";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { NgxDatatableGlobal } from "src/app/global/ngx-datatable-global/ngx-datatable-global";
import { InputMaskModule } from "@ngneat/input-mask";
import { NgHttpLoaderModule } from "ng-http-loader";
import { MyFilterPipe } from "src/app/global/ngx-datatable-global-filter.pipe";
import { FilterDropdownGlobal } from "src/app/global/filter-dropdown-global/filter-dropdown-global";
import { RetryGlobal } from "src/app/global/retry-global/retry-global";
import { NgHttpLoaderGlobal } from "./ng-http-loader-global/ng-http-loader-global";
import { TranslateModule } from "@ngx-translate/core";
import { ProgressbarModule } from "ngx-bootstrap/progressbar";
import { NgxDatatableFormGlobal } from "./ngx-datatable-form-global/ngx-datatable-form-global";
import { FormGlobal } from "./form-global/form-global";
import { GlobalSkeletonFormGlobalComponent } from "./skeleton-form-global-component/skeleton-form-global-component";
import { uploadingFileDialog } from "./uploading-file-dialog/uploading-file-dialog";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MomentDateModule } from "@angular/material-moment-adapter";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatTooltipModule } from "@angular/material/tooltip";
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from "@angular-material-components/datetime-picker";
import { gitDatetimepicker } from "./git-datetimepicker/git-datetimepicker";
import { DatePickerFormatDirective } from "./git-datepicker/date-picker-format.directive";
import { InputGlobalComponent } from "./input-global/input-global.component";
import { TextareaComponent } from "./textarea-component/textarea-component";
import { ToggleCheckboxComponent } from "./toggle-checkbox-component/toggle-checkbox-component";
import { HttpClientModule } from "@angular/common/http";
import { gitRangeDatepicker } from "./git-range-datepicker/git-range-datepicker";

@NgModule({
    declarations: [
        GlobalBreadcrumbComponent,
        GlobalSkeletonFormComponent,
        DragDropDirectiveDirective,
        GlobalHamburgerMenuComponent,
        GlobalAutoNumericComponent,
        ConfirmLeaveComponent,
        NgSelectGlobal,
        gitDatepicker,
        gitRangeDatepicker,
        TimelineGlobal,
        HttpLoaderImageComponent,
        TalentMatrixGlobal,
        gitBtnExport,
        NgxDatatableGlobal,
        MyFilterPipe,
        FilterDropdownGlobal,
        RetryGlobal,
        NgHttpLoaderGlobal,
        NgxDatatableFormGlobal,
        FormGlobal,
        GlobalSkeletonFormGlobalComponent,
        uploadingFileDialog,
        gitDatetimepicker,
        DatePickerFormatDirective,
        InputGlobalComponent,
        TextareaComponent,
        ToggleCheckboxComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        NgSelectModule,
        NgxDatatableModule,
        BsDropdownModule.forRoot(),
        ModalModule.forRoot(),
        NgxSkeletonLoaderModule.forRoot(),
        ModalModule.forRoot(),
        TimepickerModule.forRoot(),
        ProgressbarModule.forRoot(),
        BsDatepickerModule,
        NgHttpLoaderModule.forRoot(),
        InputMaskModule.forRoot({ inputSelector: "input", isAsync: true }),
        TranslateModule,
        MatNativeDateModule,
        MomentDateModule,
        MatDatepickerModule,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        ReactiveFormsModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        NgxMatNativeDateModule,
        HttpClientModule,
        NgHttpLoaderModule.forRoot(),
    ],
    exports: [
        GlobalBreadcrumbComponent,
        GlobalSkeletonFormComponent,
        DragDropDirectiveDirective,
        GlobalHamburgerMenuComponent,
        GlobalAutoNumericComponent,
        ConfirmLeaveComponent,
        NgSelectGlobal,
        gitDatepicker,
        gitRangeDatepicker,
        TimelineGlobal,
        HttpLoaderImageComponent,
        TalentMatrixGlobal,
        gitBtnExport,
        NgxDatatableGlobal,
        MyFilterPipe,
        FilterDropdownGlobal,
        RetryGlobal,
        NgHttpLoaderGlobal,
        NgxDatatableFormGlobal,
        FormGlobal,
        GlobalSkeletonFormGlobalComponent,
        uploadingFileDialog,
        gitDatetimepicker,
        DatePickerFormatDirective,
        InputGlobalComponent,
        TextareaComponent,
        ToggleCheckboxComponent,
    ],
    providers: [],
})
export class GlobalModule {}
