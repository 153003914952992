import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, Output, ViewChild, EventEmitter, HostListener, OnDestroy, ViewEncapsulation } from "@angular/core";
import { createMask } from "@ngneat/input-mask";
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { debounceTime, fromEvent, Subject, takeUntil } from "rxjs";
import { DataTypeServices } from "src/services/data-type-services";
import { MethodServices } from "src/services/method-services";
import { selectItem_Array_Inter } from "../filter-dropdown-global/filter-dropdown-global";
import { HttpLoaderImageComponent } from "../http-loader-image/http-loader-image.component";
import { SpinnerVisibilityService } from "ng-http-loader";

@Component({
    selector: "ngx-datatable-global",
    templateUrl: "ngx-datatable-global.html",
    styleUrls: ["ngx-datatable-global.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class NgxDatatableGlobal implements OnInit, AfterViewInit, OnChanges, OnDestroy {
    private latestWidth: number;

    show_DataTable: boolean = true;
    scroll_Position: any = 0;
    page_Position: any = 0;

    selector_tables_body: any;

    public httpLoaderImage = HttpLoaderImageComponent;

    dateInputMask = createMask<Date>({
        alias: "datetime",
        inputFormat: "dd-mm-yyyy",
        parser: (value: string) => {
            const values = value.split("-");
            const year = +values[2];
            const month = +values[1] - 1;
            const date = +values[0];
            return new Date(year, month, date);
        },
    });

    timeInputMask = createMask<Date>({
        alias: "datetime",
        inputFormat: "HH:MM",
        placeholder: "hh:mm",
        showMaskOnFocus: true,
        showMaskOnHover: false,
    });

    dateMonYearInputMask = createMask<Date>({
        alias: "datetime",
        inputFormat: "mm-yyyy",
        parser: (value: string) => {
            const values = value.split("-");
            const year = +values[1];
            const month = +values[0] - 1;
            return new Date(year, month, 1);
        },
    });

    currencyInputMask = createMask({
        alias: "numeric",
        groupSeparator: ",",
        digits: 2,
        digitsOptional: false,
        prefix: "",
        placeholder: "0.00",
        allowMinus: false,
        onKeyDown: (event) => {
            // console.log(event.target['value'])
        },
    });

    currencyNoDecInputMask = createMask({
        alias: "numeric",
        groupSeparator: ",",
        digits: 0,
        digitsOptional: false,
        prefix: "",
        placeholder: "_",
        allowMinus: false,
        onKeyDown: (event) => {},
    });

    numberInputMask = createMask({
        alias: "numeric",
        groupSeparator: "",
        digits: 0,
        digitsOptional: false,
        prefix: "",
        placeholder: "0",
        allowMinus: false,
    });
    numberInputMask_Temp = createMask({
        alias: "numeric",
        groupSeparator: "",
        digits: 0,
        digitsOptional: false,
        prefix: "",
        placeholder: "_",
        allowMinus: false,
        rightAlign: true,
        onKeyDown(event) {
            if (event.key.toLowerCase() == "m") {
                return false;
            }
        },
    });

    numberInputMaskDecimal_Temp = createMask({
        alias: "numeric",
        groupSeparator: "",
        digits: 2,
        digitsOptional: true,
        prefix: "",
        placeholder: "_.__",
        showMaskOnHover: true,
        allowMinus: false,
        rightAlign: true,
        autoUnmask: false, // otomatis validasi di parameter _ jadi 0 jika true
        onKeyDown(event) {
            if (event.key.toLowerCase() == "m") {
                return false;
            }
        },
    });

    positionCurrentId: any;
    positionCurrentId_Prev: any;
    positionCurrentId_Value: any;

    aktif_table: boolean = true;

    filterNoUrut = { typeColumn: "nourut" };
    filterDefault = { typeColumn: "default" };

    @Input() modelEffectiveDate: any;
    @Input() modelEffectiveDateFinal: any;
    @Input() temp: any[] = [];
    @Input() rows: any[] = [];
    @Input() selectedActive: any;
    @Input() hideSortFilterTable: any[] = []; //array sort column will hide e.g. [0,1,2]
    @Input() dataExists: boolean;
    @Input() statusFinishedApi: boolean;
    @Input() statusAODParamUrl?: boolean; // apa ada AOD yg perlu di include ke param URL hit API
    @Input() useSummary?: boolean = true; //apa perlu filter header summary atau tidak
    @Input() dataInput: any[] = [];
    @Input() tableIcon: any;
    @Input() isImageIcon: boolean = false;
    @Input() tableImage: any;
    @Input() tableName: any;
    @Input() showCreateButton: boolean;
    @Input() showViewTemplate: boolean = false;
    @Input() showSampleTemplate: boolean = false;
    @Input() statusDisabledDropdown: boolean = false; // disabled (true or false) status active or any status in dropdown toggle
    @Input() avatarDivWidth: any = "45px"; // width logo avatar above (sebelah nama form)
    @Input() avatarFontSize: any = "30px"; // font size logo avatar

    // EXPORT BUTTON OPTIONS
    @Input() dataParamsExport: any;
    @Input() exportApiGetData: any = "/api/v1/role/export";
    @Input() exportProcessName: any = "RPT_SS_SECURITY_USER";
    @Input() exportNeedAOD: any = true;
    @Input() exportIsDisabled: any = false;
    @Input() customizableExportValue: boolean = false; // for customize key value name when export data (example in transaction > check in check out)
    /*Notes: Ditambahkan pada html dan ts (html jadi customizableExportValue = true, ts ditambahin isCustomValue di data pada dataInput yang hendak fleksible, 
        lalu pas set rows awal dibuat versi CustomValue nya) [contoh: description: "example", descriptionCustomValue: "Example"]
        (CustomValue case sensitive jd bakalan keluar aneh kalau beda ketika di export☺)
    */

    @Output() createElementGlobal: any = new EventEmitter<any>();
    @Output() selectProcGlobal: any = new EventEmitter<any>();
    @Output() formProcGlobal: any = new EventEmitter<any>();
    @Output() elementClassLoadGlobal: any = new EventEmitter<any>();
    @Output() keypressGlobal: any = new EventEmitter<any>();
    @Output() showHierarchyGlobal: any = new EventEmitter<any>();
    @Output() resetPasswordGlobal?: any = new EventEmitter<any>();
    @Output() editShowGlobal: any = new EventEmitter<any>();
    @Output() rangePickerChangeGlobal: any = new EventEmitter<any>();
    @Output() reprocessFuncGlobal: any = new EventEmitter<any>();
    @Output() rollbackFuncGlobal: any = new EventEmitter<any>();
    @Output() downloadLogViewGlobal: any = new EventEmitter<any>();
    @Output() viewTemplateGlobal: any = new EventEmitter<any>();

    // untuk dropdown dengan list custom
    @Input() dropdownCustomList: selectItem_Array_Inter[] = [];

    // untuk custom status (all, active, inactive, in progress, completed, dst ... )
    @Input() headerStatusCode: any = 0; // 0 -> (all, active, inactive)
    @Input() headerStatusKey: any = "active"; // key (active) yang dikirim ke backend (e.g. ?active=true)
    headerStatusList: any[] = []; // JSON dari statusCode yang dimasukkan
    // ... end

    // untuk date range picker
    rangePicker_show: boolean = false;
    rangePicker_blockRender: boolean = true;

    // range value diisi untuk kondisi awal
    bsRangeValue_Start: any;
    bsRangeValue_End: any;

    bsRangeValue: any = [];
    @Input() headerStatusRangePicker: boolean = false; // di tampilkan atau tidak
    @Input() headerRangePickerMaxDate: any = 59; // default 60 days  (tidak mandatory di isi)
    @Input() headerRangePickerKeyStartDate: any = "startDate"; // param key untuk kirim api (e.g. startDate)
    @Input() headerRangePickerKeyEndDate: any = "endDate"; // param key untuk kirim api (e.g. endDate)
    @Input() headerRangePickerValueEndDate: any = new Date();
    // ... end

    activeRow: any;
    entries: number = 10;
    selected: any[] = [];
    arrFilterHeader: any[] = [];
    gabung: any;
    dataInputSort: any[] = [];
    widthColumn: any;
    // dataExists:boolean;

    @ViewChild("nativeFilter1") nativeFilter1: ElementRef;
    @ViewChild("nativeFilter2") nativeFilter2: ElementRef;

    @ViewChild("dataTable") dataTable: DatatableComponent;

    statusDark: boolean = false;
    tempLength: any;

    ngUnsubscribe: Subject<any> = new Subject();
    holdScrollUpdateSize = new Subject();
    holdScrollResizeWindow = new Subject();

    constructor(public methodServices: MethodServices, public spinner: SpinnerVisibilityService, public dataTypeServices: DataTypeServices) {
        this.methodServices.subject_DarkMode.pipe(takeUntil(this.ngUnsubscribe)).subscribe((result) => {
            this.methodServices.statusDark = result;
        });

        this.holdScrollUpdateSize.pipe(takeUntil(this.ngUnsubscribe), debounceTime(300)).subscribe((res) => {});

        this.holdScrollResizeWindow.pipe(takeUntil(this.ngUnsubscribe), debounceTime(100)).subscribe((res) => {});

        this.methodServices.triggerDatatableHeaderTable.pipe(takeUntil(this.ngUnsubscribe), debounceTime(300)).subscribe((value: any) => {
            if (value) {
                this.resizeHeaderTable();
            }
        });
    }

    @HostListener("window:resize", ["$event"])
    funcResize() {
        this.holdScrollResizeWindow.next(0);
    }

    resizeHeaderTable() {
        let getHeaderClass = document.getElementById("print-section").getElementsByTagName("datatable-header")[0].getElementsByClassName("datatable-header-inner")[0].getElementsByClassName("datatable-row-center")[0] as HTMLElement;
        getHeaderClass.style.transform = "translateX(0px)";

        const datatableHeader = this.dataTable.element.querySelector(".ngx-datatable .datatable-header");
        const datatableBody = this.dataTable.element.querySelector(".ngx-datatable .datatable-body");
        datatableBody.scrollLeft = 0;
        datatableHeader.scrollLeft = 0;
    }

    table_Adjust_CutOff_RightColumn() {
        setTimeout(() => {
            let datatable_header = document.querySelectorAll(".datatable-header")[0];
            let datatable_header_inner = document.querySelectorAll(".datatable-header-inner")[0];
            let datatable_row_center = document.querySelectorAll(".datatable-header .datatable-row-center")[0];

            let getValueStyle = window.getComputedStyle(datatable_row_center);

            // "datatable_header & datatable_header_inner" GET VALUE WIDTH
            let datatable_header_inner_Width = datatable_header_inner.clientWidth;
            let datatable_header_Width = datatable_header.clientWidth;
            let datatable_header_selisih = datatable_header_inner_Width - datatable_header_Width;

            // "datatable_row_center" GET STYLE TRANSFORM
            let getValueStyle_transform_element = (<HTMLElement>datatable_row_center).style.transform.toString();

            // "datatable_row_center" GET VALUE ON NUMBER FORMAT AND REMOVE "translate3d" (result -> [-603.733, 0, 0])
            let regexps = /(-?[0-9.]+)/g;
            let value_translate: any[] = getValueStyle_transform_element.replace("translate3d", "").match(regexps);
            value_translate = value_translate.map((result) => {
                return parseFloat(result);
            });
            // ... end

            if (datatable_header_selisih != value_translate[0]) {
                (<HTMLElement>datatable_row_center).style.transform = "translate3d(-" + datatable_header_selisih + "px, 0, 0)";
            }
        }, 100);
    }

    changeDateFilter(event) {
        let range1: any;
        let range2: any;
        let singleParam_startDate: any;
        let singleParamVal_startDate: any;
        let singleParam_endDate: any;
        let singleParamVal_endDate: any;

        if (this.rangePicker_blockRender) {
            return;
        }

        range1 = event[0];
        range2 = event[1];

        // dapat masuk ke arrFilterHeader & gabung dengan catatan status range picker harus 'true'
        if (this.headerStatusRangePicker) {
            let arrFilter_idx: any;
            let gabungFilterTemp: any = "";

            singleParam_startDate = this.headerRangePickerKeyStartDate + "=";
            singleParamVal_startDate = this.headerRangePickerKeyStartDate + "=" + (range1 != null ? this.methodServices.formatDate(range1, "yyyy-mm-dd") : "");

            singleParam_endDate = this.headerRangePickerKeyEndDate + "=";
            singleParamVal_endDate = this.headerRangePickerKeyEndDate + "=" + (range2 != null ? this.methodServices.formatDate(range2, "yyyy-mm-dd") : "");

            if (this.arrFilterHeader.length > 0) {
                // jika arrFilterHeader sudah ada data sebelumnya

                // START DATE
                arrFilter_idx = this.arrFilterHeader.findIndex((result) => {
                    return result.toString().split("=")[0] == this.headerRangePickerKeyStartDate.toString();
                });

                if (arrFilter_idx == -1) {
                    // tidak ketemu, maka ditambahkan langsung
                    if (singleParam_startDate != singleParamVal_startDate) {
                        this.arrFilterHeader.push(singleParamVal_startDate);
                    }
                } else {
                    // ketemu, maka digantikan
                    this.arrFilterHeader[arrFilter_idx] = singleParamVal_startDate;
                }
                // ... end START DATE

                // END DATE
                arrFilter_idx = this.arrFilterHeader.findIndex((result) => {
                    return result.toString().split("=")[0] == this.headerRangePickerKeyEndDate.toString();
                });

                if (arrFilter_idx == -1) {
                    // tidak ketemu, maka ditambahkan langsung
                    if (singleParam_endDate != singleParamVal_endDate) {
                        this.arrFilterHeader.push(singleParamVal_endDate);
                    }
                } else {
                    // ketemu, maka digantikan
                    this.arrFilterHeader[arrFilter_idx] = singleParamVal_endDate;
                }
                // ... end END DATE

                // jika arrFilterHeader tidak ada data alias kosong
            } else {
                if (singleParam_startDate != singleParamVal_startDate) {
                    this.arrFilterHeader.push(singleParamVal_startDate);
                }
                if (singleParam_endDate != singleParamVal_endDate) {
                    this.arrFilterHeader.push(singleParamVal_endDate);
                }
            }

            if (this.arrFilterHeader.length > 0) {
                this.setGabungDataFromParamFilter();

                this.dataTypeServices.arrFilterHeader = this.arrFilterHeader;
                this.dataTypeServices.gabung = this.gabung;

                if (this.gabung.substr(0, 1) == "&") {
                    this.gabung = this.gabung.slice(1);
                }

                if (this.gabung != "") {
                    this.gabung = "?" + this.gabung;
                } else {
                    this.gabung = "";
                }

                this.rangePickerChangeGlobal.emit({ param: this.gabung });
            }
        }
    }

    funcHeaderList(headerCode) {
        // All dengan value kosong berarti tidak mengirimkan status sama sekali ke backend
        switch (headerCode) {
            case 0:
                this.headerStatusList = [
                    { showText: "All", paramValue: "" },
                    {
                        showText: "Active",
                        paramValue: "true",
                    },
                    { showText: "Inactive", paramValue: "false" },
                ];
                break;
            case 1:
                this.headerStatusList = [
                    { showText: "All", paramValue: "" },
                    {
                        showText: "In Progress",
                        paramValue: "In Progress",
                    },
                    { showText: "Completed", paramValue: "Completed" },
                ];
                break;
            case 2:
                this.headerStatusList = [
                    { showText: "All", paramValue: "" },
                    {
                        showText: "In Progress",
                        paramValue: "In Progress",
                    },
                    { showText: "Approved", paramValue: "Approved" },
                    {
                        showText: "Canceled",
                        paramValue: "Canceled",
                    },
                    { showText: "Rejected", paramValue: "Rejected" },
                ];
                break;
            case 3:
                this.headerStatusList = [
                    { showText: "All", paramValue: "" },
                    {
                        showText: "New Candidate",
                        paramValue: "newcandidate",
                    },
                    { showText: "In Progress", paramValue: "inprogress" },
                    {
                        showText: "Hired",
                        paramValue: "hired",
                    },
                    { showText: "Rejected", paramValue: "rejected" },
                    { showText: "Failed", paramValue: "failed" },
                ];
                break;
            case 4:
                this.headerStatusList = [
                    { showText: "All", paramValue: "" },
                    {
                        showText: "In Progress",
                        paramValue: "In Progress",
                    },
                    { showText: "Completed", paramValue: "Completed" },
                    { showText: "Error", paramValue: "Error" },
                ];
                break;
            case 5:
                this.headerStatusList = [
                    { showText: "All", paramValue: "" },
                    {
                        showText: "In Progress",
                        paramValue: "In Progress",
                    },
                    { showText: "Completed", paramValue: "Completed" },
                    {
                        showText: "Cancelled",
                        paramValue: "Cancelled",
                    },
                    { showText: "Error", paramValue: "Error" },
                ];
                break;
            case 6:
                this.headerStatusList = [
                    { showText: "All", paramValue: "" },
                    {
                        showText: "In Progress",
                        paramValue: "In Progress",
                    },
                    { showText: "Completed", paramValue: "Completed" },
                    {
                        showText: "Rollback",
                        paramValue: "Rollback",
                    },
                    { showText: "Error", paramValue: "Error" },
                ];
                break;
            case 7:
                this.headerStatusList = [
                    { showText: "All", paramValue: "" },
                    {
                        showText: "Completed",
                        paramValue: "Completed",
                    },
                    { showText: "On Going", paramValue: "On Going" },
                ];
                break;
            case 8:
                this.headerStatusList = [
                    { showText: "All", paramValue: "" },
                    {
                        showText: "Done",
                        paramValue: "Done",
                    },
                    { showText: "On Going", paramValue: "On Going" },
                    { showText: "Expired", paramValue: "Expired" },
                ];
                break;
            case 9:
                this.headerStatusList = [
                    { showText: "All", paramValue: "" },
                    {
                        showText: "Confirmed",
                        paramValue: "Confirmed",
                    },
                    { showText: "Cancelled", paramValue: "Cancelled" },
                    {
                        showText: "Need Confirmation",
                        paramValue: "Not Confirmed",
                    },
                ];
                break;
            case 10:
                this.headerStatusList = [
                    { showText: "All", paramValue: "" },
                    {
                        showText: "In Progress",
                        paramValue: "In Progress",
                    },
                    { showText: "Completed", paramValue: "Completed" },
                    { showText: "Submitted", paramValue: "Submitted" },
                ];
                break;
            case 11:
                this.headerStatusList = [
                    { showText: "All", paramValue: "" },
                    {
                        showText: "New",
                        paramValue: "New",
                    },
                    { showText: "In Progress", paramValue: "In Progress" },
                    {
                        showText: "Completed",
                        paramValue: "Completed",
                    },
                    { showText: "Passed", paramValue: "Passed" },
                    { showText: "Failed", paramValue: "Failed" },
                ];
                break;
            case 12:
                this.headerStatusList = [
                    { showText: "All", paramValue: "" },
                    {
                        showText: "In Progress",
                        paramValue: "In Progress",
                    },
                    { showText: "Passed", paramValue: "Passed" },
                    { showText: "Failed", paramValue: "Failed" },
                ];
                break;
            case 13:
                this.headerStatusList = [
                    { showText: "All", paramValue: "" },
                    {
                        showText: "New",
                        paramValue: "New",
                    },
                    { showText: "In Progress", paramValue: "In Progress" },
                    {
                        showText: "Rejected",
                        paramValue: "Rejected",
                    },
                    { showText: "Closed", paramValue: "Closed" },
                ];
                break;
            case 14:
                this.headerStatusList = [
                    { showText: "All", paramValue: "" },
                    { showText: "In Progress", paramValue: "In Progress" },
                    { showText: "Approved", paramValue: "Approved" },
                    { showText: "Rejected", paramValue: "Rejected" },
                ];
                break;
        }
    }

    ngOnInit() {
        this.dataTypeServices.totalRecord_String = "0";
        this.dataTypeServices.totalRecord = 0;

        // isi range date ke rangePicker sebagai value awal yang di tampilkan
        if (this.headerStatusRangePicker) {
            // nilai awal range picker bergantung dari end Date 'headerRangePickerValueEndDate'
            this.bsRangeValue_Start = new Date(this.headerRangePickerValueEndDate.getFullYear(), this.headerRangePickerValueEndDate.getMonth(), this.headerRangePickerValueEndDate.getDate() - this.headerRangePickerMaxDate);
            this.bsRangeValue_End = this.headerRangePickerValueEndDate;
            this.bsRangeValue = [this.bsRangeValue_Start, this.bsRangeValue_End];
        }
        // ... end

        // CALL FUNGSI UNTUK LOAD DATA HEADER LIST (ALL, ACTIVE, INACTIVE, dst ...)
        if (this.headerStatusCode != null && typeof this.headerStatusCode != "undefined") {
            this.funcHeaderList(this.headerStatusCode);
        } else {
            this.funcHeaderList(0);
        }

        // ... end

        this.methodServices.filterTemp.pipe(takeUntil(this.ngUnsubscribe)).subscribe((result) => {
            setTimeout(() => {
                this.temp = [];
                this.temp = [...this.methodServices.filterTempObject];
            }, 150);
        });

        setTimeout(() => {
            this.temp = [...this.rows];
            this.methodServices.filterTempObject = [...this.temp];
        }, 150);

        if (this.temp.length > 0) {
            this.dataExists = true;
        }

        // kondisi jika parameter arrFilterHeader kosong, tapi di trigger parameter custom dari component luar maka diisi dari luar dulu
        // e.g. changeAOD

        this.dataTypeServices.triggerParamBlank.pipe(takeUntil(this.ngUnsubscribe)).subscribe((result) => {
            let resultTemp: any;
            let arrFilterTemp: any;

            if (typeof result == "undefined") {
                // jika trigger tanpa parameter / kosong
                this.arrFilterHeader = [];
                this.gabung = "";
                this.dataTypeServices.arrFilterHeader = [];
                this.dataTypeServices.gabung = "";
            } else {
                setTimeout(() => {
                    if (this.arrFilterHeader.length == 0) {
                        resultTemp = result;

                        if (resultTemp.toString().substr(0, 1) == "?") {
                            resultTemp = resultTemp.slice(1);
                        }

                        arrFilterTemp = resultTemp.split("&");
                        this.arrFilterHeader = arrFilterTemp;
                    }
                }, 100);
            }
        });

        // kondisi jika ada update value pada key tertentu di dalam arrFilterHeader & gabung
        // e.g. employeeId=12345 (hanya data single) & case sensitive

        this.dataTypeServices.updateParamKey.pipe(takeUntil(this.ngUnsubscribe)).subscribe((result) => {
            if (typeof result != "undefined" && result != "") {
                let splitKeyVal = result.toString().split("=");
                let newKey = splitKeyVal[0];
                let newValue = splitKeyVal[1];

                if (this.arrFilterHeader.length > 0) {
                    let findIdx = this.arrFilterHeader.findIndex((arr) => {
                        return arr.toString().split("=")[0] == newKey.toString();
                    });
                    if (findIdx != -1) {
                        this.arrFilterHeader[findIdx] = newKey.toString() + "=" + newValue.toString();
                        this.setGabungDataFromParamFilter();

                        this.dataTypeServices.arrFilterHeader = this.arrFilterHeader;
                        this.dataTypeServices.gabung = this.gabung;
                    }
                }
            }
        });

        // REMOVE SORT TABLE ICON
        this.hideSortTable();
    }

    hideSortTable() {
        if (this.hideSortFilterTable != null && typeof this.hideSortFilterTable != "undefined" && this.hideSortFilterTable.length > 0) {
            for (var i = 0; i < this.hideSortFilterTable.length; i++) {
                this.methodServices.hideSortFilterTable(this.hideSortFilterTable[i]);
            }
        }
    }

    ngAfterViewInit() {
        this.rangePicker_blockRender = true;
        this.rangePicker_show = true;

        setTimeout(() => {
            this.rangePicker_blockRender = false;
        }, 300);

        // Below Function to make sure when first initialized datatable always from most left side
        const datatableHeader = this.dataTable.element.querySelector(".ngx-datatable .datatable-header");
        const datatableBody = this.dataTable.element.querySelector(".ngx-datatable .datatable-body");
        setTimeout(() => {
            datatableHeader.scrollLeft = 0;
            datatableBody.scrollLeft = 0;
        }, 10);

        setTimeout(() => {
            //Function to sync datatable header and body when firefox scrolling
            if (navigator.userAgent.toLowerCase().indexOf("firefox") > -1) {
                // Sync scroll positions
                datatableBody.addEventListener("scroll", () => {
                    datatableHeader.scrollLeft = datatableBody.scrollLeft;
                });
            }
        }, 100);
    }

    ngOnChanges() {
        if (this.statusFinishedApi) {
            //setelah selesai hit api, parsing value kembali ke native element
            // Parsing kembali ke text box filter

            // // KONDISI JIKA MASUK KE FORM, BALIK LAGI KE TABEL (ISSUE'y arrFilterHeader jadi kosong, kecuali di dataTypeServices)
            if (this.dataTypeServices.arrFilterHeader.length > 0) {
                this.arrFilterHeader = this.dataTypeServices.arrFilterHeader;
            }
            // ...end

            if (typeof this.temp != "undefined" && this.temp.length == 0) {
                this.dataTypeServices.totalRecord = 0;
                this.dataTypeServices.totalRecord_String = "0";
            }

            this.tempLength = typeof this.temp != "undefined" ? Number(this.temp.length).toLocaleString("id-ID", { style: "decimal" }) : 0;

            if (this.arrFilterHeader.length > 0) {
                for (var i = 0; i < this.arrFilterHeader.length; i++) {
                    let equalIndex = this.arrFilterHeader[i].indexOf("=");
                    let keyIndex: any;
                    let valueIndex: any;
                    let nameDataInput: any;
                    let idNgTemplate: any;
                    let typeSummaryTemplate: any;

                    if (equalIndex != -1) {
                        keyIndex = this.arrFilterHeader[i].substr(0, equalIndex);
                        valueIndex = this.arrFilterHeader[i].substr(equalIndex + 1, this.arrFilterHeader[i].length);

                        if (this.dataInput.length > 0) {
                            nameDataInput = this.dataInput.filter((result) => {
                                return result["nameParamUrl"].toLowerCase() == keyIndex.toLowerCase();
                            });
                            if (nameDataInput.length > 0) {
                                idNgTemplate = nameDataInput[0]["idNgTemplate"];
                                typeSummaryTemplate = nameDataInput[0]["typeSummaryTemplate"];
                            }

                            setTimeout(() => {
                                var filter1 = document.getElementById(idNgTemplate) as HTMLInputElement;
                                if (typeSummaryTemplate.toLowerCase() == "date") {
                                    if (new Date(valueIndex).toString() == "Invalid Date") {
                                        filter1.value = valueIndex;
                                    } else {
                                        filter1.value = this.methodServices.formatDate(new Date(valueIndex), "dd-mm-yyyy");
                                    }
                                } else if (typeSummaryTemplate.toLowerCase() == "date-mon-year") {
                                    if (new Date(valueIndex + "-01").toString() == "Invalid Date") {
                                        filter1.value = valueIndex;
                                    } else {
                                        filter1.value = this.methodServices.formatDate(new Date(valueIndex + "-01"), "mm-yyyy");
                                    }
                                } else {
                                    filter1.value = valueIndex;
                                }
                            });
                        }
                    }
                }
            }
        }

        if (typeof this.dataInput != "undefined") {
            this.dataInputSort = [];

            for (var i = 0; i < this.dataInput.length; i++) {
                this.dataInputSort.push(this.dataInput[i]["name"]);
            }

            // menambah data sort dengan type = date (_Sort)
            if (typeof this.temp != "undefined") {
                if (this.temp.length > 0) {
                    for (var key in this.temp[0]) {
                        if (key.toString().toLowerCase().indexOf("_sort") != -1) {
                            this.dataInputSort.push(key);
                        }
                    }
                }
            }
        }
    }

    onActivate(event) {
        this.activeRow = event.row;
    }

    onSort($event) {
        let x;
        let y;
        let dataSort: boolean;
        let columnFinal: any;

        // cari type sort column antara number / text
        let typeSortColumn: any;
        let findTypeSortColumn = this.dataInput.find((result) => {
            return result["name"].toLowerCase() == $event.column.prop.toLowerCase();
        });
        if (findTypeSortColumn) {
            columnFinal = $event.column.prop;

            typeSortColumn = findTypeSortColumn["typeSortColumn"];

            // sort date
            if (typeSortColumn == "date") {
                columnFinal = $event.column.prop + "_Sort";
                let findTypeSortColumn = this.dataInputSort.find((result) => {
                    return result.toLowerCase() == columnFinal.toLowerCase();
                });
                if (!findTypeSortColumn) {
                    columnFinal = null;
                }
            } else {
                // sort selain date, jika key 'nameSortColumn' tidak ada di dataInputSort, maka ditambahkan ke dalam'y
                if (typeof findTypeSortColumn["nameSortColumn"] != "undefined") {
                    columnFinal = findTypeSortColumn["nameSortColumn"];

                    let findNameColumn = this.dataInputSort.find((res) => {
                        return res.toLowerCase() == findTypeSortColumn["nameSortColumn"].toLowerCase();
                    });
                    if (!findNameColumn) {
                        this.dataInputSort.push(findTypeSortColumn["nameSortColumn"]);
                    }
                }
            }
        }

        let titleSort = this.dataInputSort;

        if (titleSort.length > 0) {
            dataSort = false;

            titleSort.forEach((colname) => {
                if ($event.newValue == "asc") {
                    // if($event.column.prop.toLowerCase() == colname.toLowerCase()){
                    if (columnFinal.toLowerCase() == colname.toLowerCase()) {
                        this.temp.sort(function (a, b) {
                            // if(colname == 'no'){
                            if (typeSortColumn == "number" || typeSortColumn == "date") {
                                return a[colname] - b[colname];
                            } else {
                                x = a[colname].toString().toLowerCase();
                                y = b[colname].toString().toLowerCase();
                                if (x < y) {
                                    dataSort = true;
                                    return -1;
                                }

                                if (x > y) {
                                    dataSort = true;
                                    return 1;
                                }

                                if (x == y) {
                                    return 0;
                                }
                            }
                        });
                        if (dataSort == true) {
                            setTimeout(() => {
                                this.temp = [...this.temp];
                                this.methodServices.filterTempObject = [...this.temp];
                            }, 100);
                        }
                    }
                } else if ($event.newValue == "desc") {
                    if (columnFinal.toLowerCase() == colname.toLowerCase()) {
                        this.temp.sort(function (a, b) {
                            if (typeSortColumn == "number" || typeSortColumn == "date") {
                                return b[colname] - a[colname];
                            } else {
                                x = a[colname].toString().toLowerCase();
                                y = b[colname].toString().toLowerCase();
                                if (x < y) {
                                    dataSort = true;
                                    return -1;
                                }

                                if (x > y) {
                                    dataSort = true;
                                    return 1;
                                }

                                if (x == y) {
                                    return 0;
                                }
                            }
                        });
                        if (dataSort == true) {
                            this.temp.reverse();
                            setTimeout(() => {
                                this.temp = [...this.temp];
                                this.methodServices.filterTempObject = [...this.temp];
                            }, 100);
                        }
                    }
                }
            });
        }
        return;
    }

    keypressFilter(event, column?, columnType?) {
        let temp: any;
        let tanggal: any;
        let bulan: any;
        let tahun: any;
        let tanggal_join: any;
        let paramRange: any;
        let rangeDate1: any;
        let rangeDate2: any;

        // jika header RANGE PICKER di aktifkan, maka di isi ke variable "paramRange"
        if (this.headerStatusRangePicker) {
            if (!isNaN(this.bsRangeValue[0]) && !isNaN(this.bsRangeValue[1])) {
                rangeDate1 = this.methodServices.formatDate(this.bsRangeValue[0], "yyyy-mm-dd");
                rangeDate2 = this.methodServices.formatDate(this.bsRangeValue[1], "yyyy-mm-dd");

                // HAPUS DULU START DATE jika ditemukan
                let range1_idx = this.arrFilterHeader.findIndex((result) => {
                    return result.toString().split("=")[0] == this.headerRangePickerKeyStartDate;
                });
                if (range1_idx != -1) {
                    this.arrFilterHeader.splice(range1_idx, 1);
                }

                // HAPUS DULU END DATE jika ditemukan
                let range2_idx = this.arrFilterHeader.findIndex((result) => {
                    return result.toString().split("=")[0] == this.headerRangePickerKeyEndDate;
                });
                if (range2_idx != -1) {
                    this.arrFilterHeader.splice(range2_idx, 1);
                }

                // MASUKKIN START DATE & END DATE YANG TERBARU
                this.arrFilterHeader.push(this.headerRangePickerKeyStartDate + "=" + rangeDate1);
                this.arrFilterHeader.push(this.headerRangePickerKeyEndDate + "=" + rangeDate2);
            }
        }

        temp = event.target.value;
        let eventTargetValue: any;

        eventTargetValue = event.target.value;

        if (typeof columnType != "undefined" && columnType.toLowerCase() == "date") {
            temp = event.target.value;

            if (temp == "") {
                //tanggal kosong
                eventTargetValue = "";
            } else {
                let arr = temp.split("-");

                tanggal = Number(arr[0]);
                bulan = Number(arr[1]);
                tahun = Number(arr[2]);
                tanggal_join = new Date(tahun, bulan - 1, tanggal);

                if (tanggal_join == "Invalid Date") {
                    eventTargetValue = temp;
                } else {
                    eventTargetValue = this.methodServices.formatDate(tanggal_join, "yyyy-mm-dd");
                }
            }
        }

        if (typeof columnType != "undefined" && columnType.toLowerCase() == "date-mon-year") {
            temp = event.target.value;

            if (temp == "") {
                //tanggal kosong
                eventTargetValue = "";
            } else {
                let arr = temp.split("-");

                // 09-2022 -> 2022-09
                tanggal = 1;
                bulan = Number(arr[0]);
                tahun = Number(arr[1]);
                tanggal_join = new Date(tahun, bulan - 1, tanggal);

                if (tanggal_join == "Invalid Date") {
                    eventTargetValue = temp;
                } else {
                    eventTargetValue = this.methodServices.formatDate(tanggal_join, "yyyy-mm");
                }
            }
        }
        // ... end mon-year

        if (columnType == "text") {
            eventTargetValue = eventTargetValue.toString().trim();
        }

        if (columnType == "currency") {
            eventTargetValue = eventTargetValue.toString().trim().replace(/[,]/g, "");
            if (eventTargetValue.indexOf(".__") != -1) {
                eventTargetValue = eventTargetValue.toString().trim().replace(/\.__/g, "");
            }
            if (eventTargetValue.indexOf(".00") != -1) {
                eventTargetValue = eventTargetValue
                    .toString()
                    .trim()
                    .replace(/\.[0]{2}/g, "");
            }
        }

        if (columnType == "currency_no_decimal") {
            eventTargetValue = eventTargetValue.toString().trim().replace(/[,]/g, "");
            eventTargetValue = eventTargetValue.toString().trim().replace(/_/g, "");
        }

        if (columnType == "time") {
            if (!this.dataTypeServices.checkIsTime(eventTargetValue)) {
                eventTargetValue = "";
            }
        }

        let singleParam = column + "=";
        let singleParamVal = column + "=" + eventTargetValue;

        if (this.arrFilterHeader.length == 0) {
            if (singleParam != singleParamVal) {
                this.arrFilterHeader.push(singleParamVal);
            }
        } else {
            let findParam = this.arrFilterHeader.findIndex((result) => {
                return result.toString().split("=")[0] + "=" == singleParam;
            });
            if (findParam != -1) {
                this.arrFilterHeader.splice(findParam, 1);

                if (singleParam != singleParamVal) {
                    this.arrFilterHeader.push(singleParamVal);
                }
            } else {
                if (singleParam != singleParamVal) {
                    this.arrFilterHeader.push(singleParamVal);
                }
            }
        }

        this.setGabungDataFromParamFilter();

        // Range Date picker
        if (typeof paramRange != "undefined" && paramRange != "") {
            this.gabung = this.gabung + "&" + paramRange;
        }

        // function untuk load AOD dan selected active
        this.addAodNSelectedActive();
        // ...end

        if (this.gabung.substr(0, 1) == "&") {
            this.gabung = this.gabung.slice(1);
        }

        if (this.gabung != "") {
            this.gabung = "?" + this.gabung;
        } else {
            this.gabung = "";
        }

        this.dataTypeServices.gabung = this.gabung;
        this.dataTypeServices.arrFilterHeader = this.arrFilterHeader;

        if (event.key == "Enter") {
            // hit api

            this.gabung = this.encodeUri_custom(this.gabung);

            this.keypressGlobal.emit({ param: this.gabung });

            this.resizeHeaderTable();
        }
    }

    addAodNSelectedActive() {
        let active: any;

        if (typeof this.modelEffectiveDateFinal == "string") {
            this.modelEffectiveDateFinal = new Date(this.modelEffectiveDate);
        }

        let aod = this.methodServices.formatDate(this.modelEffectiveDateFinal, "YYYY-MM-DD");

        if (this.statusAODParamUrl) {
            if (this.gabung != "" && typeof this.gabung != "undefined" && this.gabung != null) {
                // cek apakah aod sudah ada di variable gabung, jika tidak ada baru di tambah aod nya, jika ada maka di skip
                let findAodinGabung = this.gabung.split("&").findIndex((result) => {
                    return result.toLowerCase().split("=")[0] + "=" == "aod=" || result.toLowerCase().split("=")[0] + "=" == "?aod=";
                });
                if (findAodinGabung == -1) {
                    active = "aod=" + aod;
                } else {
                    // jika ada aod, maka harus dihapus dari (this.gabung) dulu, baru update aod yg baru
                    let gabungTempArr: any;
                    let gabungTemp: any;

                    gabungTempArr = this.gabung.split("&");
                    gabungTempArr.splice(findAodinGabung, 1);

                    gabungTemp = gabungTempArr.join("&");

                    if (gabungTemp != "" && gabungTemp != null && typeof gabungTemp != "undefined") {
                        this.gabung = gabungTemp;
                    } else {
                        this.gabung = "";
                    }

                    active = "aod=" + aod;
                }
                // ... end
            } else {
                active = "aod=" + aod;
            }

            // PUSH aod KE arrFilterHeader
            if (this.arrFilterHeader.length > 0) {
                let findArrFilter = this.arrFilterHeader.findIndex((result) => {
                    return result.toString().toLowerCase().split("=")[0] + "=" == "aod=";
                });
                if (findArrFilter >= 0) {
                    this.arrFilterHeader.splice(findArrFilter, 1);
                }
            }

            this.arrFilterHeader.push("aod=" + aod);
            // ... END PUSH aod
        }

        if (typeof active == "undefined") {
            active = "";
        }

        if (this.arrFilterHeader.length > 0) {
            // hapus active dari arrFilterHeader
            let findArrFilter = this.arrFilterHeader.findIndex((result) => {
                return result.toString().toLowerCase().split("=")[0] + "=" == this.headerStatusKey + "=";
            });
            if (findArrFilter >= 0) {
                this.arrFilterHeader.splice(findArrFilter, 1);
            }
            // ... END hapus active
        }

        // selectedActive (All, Active, Inactive, or others ...)
        let activeTemp: any = "";

        let findActiveTemp: any;
        findActiveTemp = this.headerStatusList.find((result) => {
            return result["showText"].toLowerCase() == this.methodServices.selectedActive.toLowerCase();
        });
        if (findActiveTemp) {
            if (findActiveTemp["paramValue"] != "") {
                activeTemp = "&" + this.headerStatusKey + "=";
                activeTemp += findActiveTemp["paramValue"];
                this.arrFilterHeader.push(this.headerStatusKey + "=" + findActiveTemp["paramValue"]);
            }
        }

        if (this.gabung != "" && typeof this.gabung != "undefined" && this.gabung != null) {
            // cek apakah active sudah ada di variable gabung, jika tidak ada baru di tambah active nya, jika ada maka di skip
            let findAodinGabung = this.gabung.split("&").findIndex((result) => {
                return result.toLowerCase().split("=")[0] + "=" == this.headerStatusKey + "=";
            });
            if (findAodinGabung == -1) {
                active += activeTemp;
            }
            // ... end
        } else {
            active += activeTemp;
        }

        if (typeof active != "undefined" && active != "") {
            let gabungActiveTemp: any = "";

            gabungActiveTemp = typeof this.gabung != "undefined" && this.gabung != "" && this.gabung != null ? this.gabung : "";

            if (active.substr(0, 1) == "&") {
                this.gabung = gabungActiveTemp + active;
            } else {
                this.gabung = gabungActiveTemp + "&" + active;
            }
        }
    }

    createElement() {
        // emit ke depan
        this.createElementGlobal.emit();
    }

    formProc(rowIndex) {
        let selected: any = {};
        if (this.temp.length > 0) {
            selected = this.temp[rowIndex];

            this.formProcGlobal.emit(selected);
        }
    }

    elementClassLoad() {
        // emit ke depan
        this.elementClassLoadGlobal.emit({ effDate: this.modelEffectiveDateFinal, param: this.gabung });
        this.dataTypeServices.totalRecord = 0;
        this.dataTypeServices.totalRecord_String = "0";
        if (typeof this.dataTable != "undefined") {
            this.dataTable.sorts = [];
        }

        this.resizeHeaderTable();
    }

    entriesChange($event) {
        this.spinner.show();
        this.entries = Number($event.target.value);
        this.spinner.hide();
    }

    selectedActiveProc(event) {
        // emit ke depan
        this.selectedActive = event["showText"].substring(0, 1).toUpperCase() + event["showText"].slice(1);
        this.methodServices.selectedActive = event["showText"].substring(0, 1).toUpperCase() + event["showText"].slice(1);

        this.selectProc(event);
    }

    selectProc(event) {
        // input dari effective date
        if (typeof this.modelEffectiveDateFinal == "string") {
            this.modelEffectiveDateFinal = new Date(this.modelEffectiveDate);
        }

        let param: any;
        let paramRange: any; // filter header

        this.addAodNSelectedActive();

        this.setGabungDataFromParamFilter();
        // Range Date picker
        if (typeof paramRange != "undefined" && paramRange != "") {
            this.gabung = this.gabung + "&" + paramRange;
        }
        // active : true / false / all ('')
        if (typeof param != "undefined" && param != "") {
            this.gabung = this.gabung + "&" + param;
        }

        if (this.gabung.substr(0, 1) == "&") {
            this.gabung = this.gabung.slice(1);
        }
        if (this.gabung != "") {
            this.gabung = "?" + this.gabung;
        } else {
            this.gabung = "";
        }

        this.selectProcGlobal.emit({
            selectedActive: this.methodServices.selectedActive,
            gabung: this.gabung,
        });

        this.resizeHeaderTable();

        // dataParamExport
    }

    showHierarchy(rowIndex) {
        let selected: any = {};
        if (this.temp.length > 0) {
            selected = this.temp[rowIndex];
            this.showHierarchyGlobal.emit(selected);
        }
    }

    resetPassword(rowIndex) {
        let selected = this.temp[rowIndex];
        this.resetPasswordGlobal.emit(selected);
    }

    editShow(rowIndex) {
        let idx = this.temp[rowIndex]["indexRows"];

        let selected = this.rows[idx];

        this.editShowGlobal.emit(selected);
    }
    reprocessFunc(rowIndex) {
        this.reprocessFuncGlobal.emit(rowIndex);
    }

    rollbackFunc(rowIndex) {
        this.rollbackFuncGlobal.emit(rowIndex);
    }

    downloadAssessmentLog(idx) {
        window.open(this.temp[idx].logUrl);
    }

    downloadViewLog(idx) {
        window.open(this.temp[idx].logFile);
    }

    downloadLog(idx) {
        let urlFle = this.temp[idx].logFile;
        window.open(urlFle);
    }

    downloadLogView(idx, attr) {
        this.downloadLogViewGlobal.emit(idx, attr);
    }

    download(idx, attr) {
        let urlFle = attr == "report" ? this.temp[idx].reportUrl : this.temp[idx].logFile;
        window.open(urlFle);
    }

    letterFunction(idx, type) {
        switch (type) {
            case "download":
                this.reprocessFuncGlobal.emit(this.temp[idx]);
                break;
            case "delete":
                this.rollbackFuncGlobal.emit(this.temp[idx]);
                break;
        }
    }

    tesClick() {
        this.dataTypeServices.triggerDark.next("dark");

        // UBAH BACKGROUND COLOR COLUMN
        var a = document.querySelectorAll("ngx-datatable.ngx-datatable.material .datatable-header .datatable-header-cell");
        for (var i = 0; i < a.length; i++) {
            if ((a[i] as HTMLElement).style.backgroundColor == "red") {
                (a[i] as HTMLElement).style.backgroundColor = "#3E8BFF";
            } else {
                (a[i] as HTMLElement).style.backgroundColor = "red";
            }
        }
    }

    focusProc(event) {
        this.positionCurrentId = event.target.id;
    }

    blurProc(event) {}

    mouseEnterProc(event) {}

    funcNumberMask(placeholder?) {
        this.numberInputMask = createMask({
            alias: "numeric",
            groupSeparator: "",
            digits: 0,
            digitsOptional: false,
            prefix: "",
            placeholder: placeholder,
            allowMinus: false,
        });
    }

    selectedValueOutput(val, column?) {
        let gabungTemp: any;
        let gabungTempArr: any;
        let paramSingle: any;
        let param: any;
        let valTemp: any;

        let rangeDate1: any;
        let rangeDate2: any;

        // function untuk load AOD dan selected active
        this.addAodNSelectedActive();
        // ...end

        // jika header RANGE PICKER di aktifkan, maka di isi ke variable "paramRange"
        if (this.headerStatusRangePicker) {
            if (!isNaN(this.bsRangeValue[0]) && !isNaN(this.bsRangeValue[1])) {
                rangeDate1 = this.methodServices.formatDate(this.bsRangeValue[0], "yyyy-mm-dd");
                rangeDate2 = this.methodServices.formatDate(this.bsRangeValue[1], "yyyy-mm-dd");

                // HAPUS DULU START DATE jika ditemukan
                let range1_idx = this.arrFilterHeader.findIndex((result) => {
                    return result.toString().split("=")[0] == this.headerRangePickerKeyStartDate;
                });
                if (range1_idx != -1) {
                    this.arrFilterHeader.splice(range1_idx, 1);
                }

                // HAPUS DULU END DATE jika ditemukan
                let range2_idx = this.arrFilterHeader.findIndex((result) => {
                    return result.toString().split("=")[0] == this.headerRangePickerKeyEndDate;
                });
                if (range2_idx != -1) {
                    this.arrFilterHeader.splice(range2_idx, 1);
                }

                // MASUKKIN START DATE & END DATE YANG TERBARU
                this.arrFilterHeader.push(this.headerRangePickerKeyStartDate + "=" + rangeDate1);
                this.arrFilterHeader.push(this.headerRangePickerKeyEndDate + "=" + rangeDate2);
            }
        }
        // ... end RANGE PICKER

        paramSingle = column + "=";

        if (val != "" && typeof val != "undefined" && val != null) {
            valTemp = val["paramUrl"];

            param = column + "=" + valTemp;

            if (this.arrFilterHeader.length > 0) {
                // BERSIHKAN DATA YANG KOSONG / NULL / UNDEFINED
                let arrClean = this.arrFilterHeader.filter(Boolean);
                this.arrFilterHeader = [...arrClean];

                this.setGabungDataFromParamFilter();
            }

            if (typeof this.gabung != "undefined" && this.gabung != null && this.gabung != "") {
                if (this.gabung.substr(0, 1) == "?") {
                    gabungTemp = this.gabung.slice(1);
                } else {
                    gabungTemp = this.gabung;
                }

                gabungTempArr = gabungTemp.split("&");

                // gabungTempArr
                let findGabungTempArr_Index = gabungTempArr.findIndex((result) => {
                    return result.toLowerCase().split("=")[0] + "=" == paramSingle.toLowerCase();
                });

                if (findGabungTempArr_Index >= 0) {
                    gabungTempArr.splice(findGabungTempArr_Index, 1);
                }

                // arrFilterHeader
                let findArrFilterHeader_Index = this.arrFilterHeader.findIndex((result) => {
                    return result.toLowerCase().split("=")[0] + "=" == paramSingle.toLowerCase();
                });
                if (findArrFilterHeader_Index >= 0) {
                    this.arrFilterHeader.splice(findArrFilterHeader_Index, 1);
                }

                // push gabungtemparr & arrfilterheader
                if (param != paramSingle) {
                    gabungTempArr.push(param);
                    this.arrFilterHeader.push(param);
                }
                this.gabung = gabungTempArr.join("&");
            } else {
                if (param != paramSingle) {
                    this.gabung = param;
                    this.arrFilterHeader.push(param);
                }
            }

            // emit keluar (trigger keypressglobal)
            if (this.gabung != "") {
                this.gabung = "?" + this.gabung;
            } else {
                this.gabung = "";
            }

            this.dataTypeServices.gabung = this.gabung;
            this.dataTypeServices.arrFilterHeader = this.arrFilterHeader;

            this.gabung = this.encodeUri_custom(this.gabung);

            this.keypressGlobal.emit({ param: this.gabung });
        } else {
            // KONDISI JIKA (VAL) KOSONG KARENA DELETE ATAU CLICK CLEAR BUTTON

            if (this.arrFilterHeader.length > 0) {
                this.setGabungDataFromParamFilter();
            }

            if (typeof this.gabung != "undefined" && this.gabung != null && this.gabung != "") {
                if (this.gabung.substr(0, 1) == "?") {
                    gabungTemp = this.gabung.slice(1);
                } else {
                    gabungTemp = this.gabung;
                }

                gabungTempArr = gabungTemp.split("&");

                // gabungtemparr
                let findGabungTempArr_Index = gabungTempArr.findIndex((result) => {
                    return result.toLowerCase().split("=")[0] + "=" == paramSingle.toLowerCase();
                });
                if (findGabungTempArr_Index >= 0) {
                    gabungTempArr.splice(findGabungTempArr_Index, 1);
                }

                // arrFilterHeader
                let findArrFilterHeader_Index = this.arrFilterHeader.findIndex((result) => {
                    return result.toLowerCase().split("=")[0] + "=" == paramSingle.toLowerCase();
                });
                if (findArrFilterHeader_Index >= 0) {
                    this.arrFilterHeader.splice(findArrFilterHeader_Index, 1);
                }

                if (gabungTempArr.length > 0) {
                    this.gabung = gabungTempArr.join("&");
                } else {
                    this.gabung = "";
                }

                // emit keluar (trigger keypressglobal)
                if (this.gabung != "") {
                    this.gabung = "?" + this.gabung;
                } else {
                    this.gabung = "";
                }

                this.dataTypeServices.gabung = this.gabung;
                this.dataTypeServices.arrFilterHeader = this.arrFilterHeader;

                this.gabung = this.encodeUri_custom(this.gabung);

                this.keypressGlobal.emit({ param: this.gabung });
            }
        }
    }

    encodeUri_custom(val: any) {
        let val_temp: any;
        if (typeof val != "undefined" && val != null && val != "") {
            val_temp = val.replace(/\\/g, "%5c");
        }
        return val_temp;
    }

    viewTemplate() {
        this.viewTemplateGlobal.emit();
    }

    onResize(event) {
        let resIdx = this.dataInput.findIndex((result) => result["name"] == event.column.prop);
        if (resIdx) {
            this.dataInput[resIdx]["width"] = event.newValue;
        }

        this.hideSortTable();

        this.table_AdjustSizeHeaderBody();

        this.restore_cutoff_column();
    }

    fromEvent_ScrollFunc() {
        let setInt_tables = setInterval(() => {
            this.selector_tables_body = document.querySelector("ngx-datatable .datatable-body");
            if (this.selector_tables_body != null) {
                fromEvent(this.selector_tables_body, "scroll")
                    .pipe(debounceTime(500), takeUntil(this.ngUnsubscribe))
                    .subscribe((res) => {
                        let datatable_body: any = document.querySelector(".datatable-body");
                        let client_width: any = datatable_body.clientWidth;
                        let max_scroll_width: any = datatable_body.scrollWidth - datatable_body.clientWidth;
                        if (max_scroll_width <= client_width) {
                            this.restore_cutoff_column();
                        }
                    });
                clearInterval(setInt_tables);
            }
        });
    }

    onScroll(event) {
        if (this.dataTable && this.dataTable.element.clientWidth !== this.latestWidth) {
            this.latestWidth = this.dataTable.element.clientWidth;
        }

        this.scroll_Position = event?.["offsetX"] ?? 0;

        this.holdScrollUpdateSize.next(this.scroll_Position);
    }

    table_AdjustSizeHeaderBody() {
        // UNTUK CUT OFF ISSUE TABLE
        setTimeout(() => {
            let headerCell = document.querySelectorAll(".datatable-header-cell");
            let bodyCell_RowWrapper = document.querySelectorAll(".datatable-row-wrapper")[0];
            for (var i = 0; i < headerCell.length; i++) {
                // GET ALL BODY CELL
                let bodyCell_RowWrapper_cell = bodyCell_RowWrapper.querySelectorAll(".datatable-body-cell");

                // ADJUST WIDTH HEADER DARI BODY CELL JIKA TIDAK SESUAI ANTARA HEADER DAN BODY
                if (headerCell.item(i).clientWidth < bodyCell_RowWrapper_cell.item(i).clientWidth) {
                    (<HTMLElement>headerCell.item(i)).style.width = bodyCell_RowWrapper_cell.item(i).clientWidth.toString() + "px";
                }
            }
        }, 100);
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next(void 0);
        this.ngUnsubscribe.complete();
    }

    restore_cutoff_column() {
        setTimeout(() => {
            // ambil scroll position
            let tableBody: any = document.getElementsByClassName("datatable-body");

            // dapat posisi boundingClientRect table dari sisi left
            let rowCenter: any = document.getElementsByClassName("datatable-row-center")[0];
            let boundingClientRect: any = (<HTMLElement>rowCenter).style.transform;
            let boundingClientRect_final: any;
            boundingClientRect_final = boundingClientRect
                .toString()
                .replace(/translate3d\(/g, "")
                .replace(/\)/g, "");

            // (e.g. transform: translate3d(-525.6px, 0px, 0px))
            let boundingClientRect_final_arr_x: any;
            boundingClientRect_final_arr_x = parseFloat(boundingClientRect_final.split(",")[0]);
            // ... end rowcenter

            // console.log("Transform Row Center (boundingClientRect X) : " + boundingClientRect_final_arr_x)

            // ukuran width full dari datatable yang sampai overflow
            let table_full_width: any = (<HTMLElement>rowCenter).clientWidth;

            // selisih antara (table_full_width + boundingClientRect_final_arr_x), dimana seharusnya 'table_full_width' bernilai minus
            let dx_width: any = Math.ceil(table_full_width + boundingClientRect_final_arr_x);

            // width dari container luar yang membungkus table (ukuran tetap alias 'div')
            let containerWidth: any = document.querySelectorAll(".ngx-datatable")[0];
            let clientWidth: any = (<HTMLElement>containerWidth).clientWidth - 1;

            //add + 5 untuk mencegah ketika scroll full akan muncul glitch karna bawaan panjang header dan list body yg berbeda
            if (clientWidth > dx_width + 5) {
                // SELISIH
                this.show_DataTable = false;
                setTimeout(() => {
                    this.show_DataTable = true;

                    this.hideSortTable();

                    setTimeout(() => {
                        if (document.querySelector("ngx-datatable .datatable-body") != null) {
                            document.querySelector("ngx-datatable .datatable-body").scrollLeft = this.scroll_Position;

                            this.dataTable.offset = this.page_Position;
                        }
                    }, 10);
                }, 5);
            }
        }, 50);
    }

    adjust_rest_of_cutoff() {
        // start update header size
        // posisi yang benar adalah dari datatable-row-right
        let rowRight: any = document.querySelector(".datatable-header .datatable-header-inner .datatable-row-right");
        let rowRight_transform: any = rowRight.style.transform;
        let rowRight_transform_final: any;
        rowRight_transform_final = rowRight_transform
            .toString()
            .replace(/translate3d\(/g, "")
            .replace(/\)/g, "");

        // (e.g. transform: translate3d(-525.6px, 0px, 0px))
        let rowRight_transform_arr_x: any;
        rowRight_transform_arr_x = parseFloat(rowRight_transform_final.split(",")[0]);
        // ... end rowcenter

        // update ke row-center translate3d dari rowRight_transform_arr_x
        let rowCenter: any = document.querySelectorAll(".datatable-header .datatable-header-inner .datatable-row-center")[0];
        rowCenter.style.transform = "translate3d(" + rowRight_transform_arr_x + "px,0px,0px)";
        // ... end update header size
    }

    onPage(event) {
        this.page_Position = event.offset;
    }

    setGabungDataFromParamFilter() {
        let tempArrFilter = JSON.parse(JSON.stringify(this.arrFilterHeader));

        if (tempArrFilter) {
            tempArrFilter.forEach((data: any, index: number) => {
                tempArrFilter[index] = data.replaceAll("&", "%26");
            });
        }

        this.gabung = tempArrFilter.join("&");
    }
}
