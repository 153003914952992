import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { selectItem_Array_Inter } from "src/app/global/filter-dropdown-global/filter-dropdown-global";
import { dataInputType } from "src/app/global/ngx-datatable-form-global/ngx-datatable-form-global";
import { MethodServices } from "./method-services";

export interface generate_Format_API_attr {
    date?: any[];
    currency?: any[];
    boolean?: {
        key: any;
        value_true: any;
        value_false: any;
    }[];
}

@Injectable({ providedIn: "root" })
export class DataTypeServices {
    // DATA MAXLENGTH PROTECT
    sc_name: any = 150; //varchar
    sc_value: any = 255; // ng-select //varchar
    sc_phone_no: any = 20; //varchar
    sc_description: any = 500; //varchar
    sc_address: any = 500; //varchar
    sc_amount: any = 14; // 16,2
    sc_email: any = 100; //varchar
    sc_encryption: any = 500; //varchar
    sc_general_varchar: any = 255; //varchar
    sc_identification_no: any = 50; //varchar
    sc_multiple_uuid: any = 500; //varchar
    sc_path_upload: any = 500; //varchar
    sc_uuid: any = 36; //varchar
    sc_geolocation_sign: any = 8; //significant digit = double = 16.8
    sc_geolocation_dec: any = 8; //decimal digit = double = 16.8
    sc_gpa: any = 4; //decimal digit = double 3.29
    sc_age: any = 3; //number of age
    sc_length_of_service: any = 6; //number of length of service
    sc_general_text: any = 1020; //text type
    sc_point: any = 10;
    sc_character: any = 1;
    sc_shift: any = 7; //every number in time-management shift menu
    sc_year: any = 4; //year text
    yes_or_no: any = 3; //for boolean
    sc_decimal: any = 5; //for decimal
    sc_other: any; //yg tidak ada batasan (sc_boolean, sc_date, sc_time, sc_datetime)
    sc_number: any = 1020;
    sc_remark: any = 1020;
    sc_article: any = 1020;
    // END DATA MAXLENGTH PROTECT

    // Show label record in datatable footer
    totalRecord: any;
    totalRecord_String: any;
    totalShowRecord: any;
    totalSelisihRecord: any;
    footerHeight: any;
    // ...end

    // TRIGGER CHANGE AOD (FOR TABLE FILTER)
    arrFilterHeader: any[] = [];
    gabung: any;
    arrFilterHeader_Form: any = {};
    gabung_Form: any = {};

    // ...end

    disableBtnWritten: boolean = false;

    // DARK MODE
    triggerDark = new Subject();
    // ...end

    // TRIGGER PARAMETER KOSONG ngx-datatable-global
    triggerParamBlank = new Subject();
    // ...end

    // TRIGGER UPDATE KEY-VALUE TERTENTU KE DALAM NGX-DATATABLE-GLOBAL
    updateParamKey = new Subject();
    // ...end

    //Disable button for Tools > Process manager
    disableButtonProcessManager: boolean = true;

    //Disable button for Payroll > Closing
    disableButtonPayrollClosing: boolean = false;

    //Disable button for Payroll > tax process
    disableButtonTaxProcess: boolean = false;

    //Disable button for tools > import data
    disableButtonImportData: boolean = true;

    //Disable button for payroll > costing
    disableButtonCostingPayroll: boolean = false;

    //Disable button for payroll > process & result
    disableButtonProcessResult: boolean = false;

    //Disabled button for employee submenu when hamburger clicked
    disabledEmployeeFormMenu: boolean = false;

    // KHUSUS UNTUK HISTORY dataInput yang ada di "(NGX-DATATABLE-FORM-GLOBAL)"
    dropdownOffShift: selectItem_Array_Inter[] = [
        { showText: "Yes", paramUrl: "yes" },
        {
            showText: "No",
            paramUrl: "no",
        },
    ];

    dataInputHistory: dataInputType[] = [
        {
            name: "effectiveDate",
            nameParamUrl: "effectiveDate",
            label: "Effective Date",
            width: 129,
            headerClass: "text-left",
            cellClass: "text-left",
            statusCellDataExists: true,
            statusSummaryTemplate: true,
            typeSummaryTemplate: "date",
            statusFormProc: true,
            noDataDisplay: true,
            typeColumn: "default",
            typeSortColumn: "date",
        },
        {
            name: "active",
            nameParamUrl: "active",
            label: "Active",
            width: 157,
            headerClass: "text-left",
            cellClass: "text-left",
            statusCellDataExists: true,
            statusSummaryTemplate: true,
            typeSummaryTemplate: "dropdown",
            dropdownCustomList: this.dropdownOffShift,
            statusFormProc: false,
            noDataDisplay: false,
            typeColumn: "default",
            typeSortColumn: "text",
        },
        {
            name: "modifiedBy",
            nameParamUrl: "modifiedBy",
            label: "Modified By",
            width: 137,
            headerClass: "text-left",
            cellClass: "text-left",
            statusCellDataExists: true,
            statusSummaryTemplate: true,
            typeSummaryTemplate: "text",
            statusFormProc: false,
            noDataDisplay: false,
            typeColumn: "default",
            typeSortColumn: "text",
        },
        {
            name: "modifiedDate",
            nameParamUrl: "modifiedDate",
            label: "Modified Date",
            width: 159,
            headerClass: "text-left",
            cellClass: "text-left",
            statusCellDataExists: true,
            statusSummaryTemplate: true,
            typeSummaryTemplate: "date",
            statusFormProc: false,
            noDataDisplay: false,
            typeColumn: "default",
            typeSortColumn: "date",
        },
    ];

    dataInputEligibilityProfile: dataInputType[] = [
        {
            name: "no",
            nameParamUrl: "no",
            label: "No",
            width: 70,
            minWidth: 70, // undefined -> jika tdk ada
            maxWidth: 70, // undefined -> jika tdk ada
            headerClass: "text-left",
            cellClass: "text-left",
            statusCellDataExists: true,
            statusSummaryTemplate: true,
            typeSummaryTemplate: null, // text, currency, date, number, number_decimal, time
            statusFormProc: false,
            noDataDisplay: false,
            typeColumn: "nourut", // bisa custom dengan komponen global (default, nourut, dst)
            typeSortColumn: "number", // 'number' or 'text' or 'date'
            sortable: false,
        },
        {
            name: "name",
            nameParamUrl: "name",
            label: "Name",
            width: 200,
            minWidth: undefined,
            maxWidth: undefined,
            headerClass: "text-left",
            cellClass: "text-left",
            statusCellDataExists: true,
            statusSummaryTemplate: true,
            typeSummaryTemplate: "text",
            statusFormProc: false,
            noDataDisplay: true,
            typeColumn: "default",
            typeSortColumn: "text",
            cellIsEmpty: false,
        },
        {
            name: "gender",
            nameParamUrl: "gender",
            label: "Gender",
            width: 180,
            minWidth: undefined,
            maxWidth: undefined,
            headerClass: "text-left",
            cellClass: "text-left",
            statusCellDataExists: true,
            statusSummaryTemplate: true,
            typeSummaryTemplate: "text",
            statusFormProc: false,
            noDataDisplay: false,
            typeColumn: "default",
            typeSortColumn: "text",
        },
        {
            name: "maritalStatus",
            nameParamUrl: "maritalStatus",
            label: "Marital Status",
            width: 200,
            minWidth: undefined,
            maxWidth: undefined,
            headerClass: "text-left",
            cellClass: "text-left",
            statusCellDataExists: true,
            statusSummaryTemplate: true,
            typeSummaryTemplate: "text",
            statusFormProc: false,
            noDataDisplay: false,
            typeColumn: "default",
            typeSortColumn: "text",
        },
        {
            name: "religion",
            nameParamUrl: "religion",
            label: "Religion",
            width: 150,
            minWidth: undefined,
            maxWidth: undefined,
            headerClass: "text-left",
            cellClass: "text-left",
            statusCellDataExists: true,
            statusSummaryTemplate: true,
            typeSummaryTemplate: "text",
            statusFormProc: false,
            noDataDisplay: false,
            typeColumn: "default",
            typeSortColumn: "text",
        },
        {
            name: "age",
            nameParamUrl: "age",
            label: "Age",
            width: 120,
            minWidth: undefined,
            maxWidth: undefined,
            headerClass: "text-left",
            cellClass: "text-left",
            statusCellDataExists: true,
            statusSummaryTemplate: true,
            typeSummaryTemplate: "number",
            statusFormProc: false,
            noDataDisplay: false,
            typeColumn: "default",
            typeSortColumn: "number",
        },
        {
            name: "companyName",
            nameParamUrl: "companyName",
            label: "Company",
            width: 200,
            minWidth: undefined,
            maxWidth: undefined,
            headerClass: "text-left",
            cellClass: "text-left",
            statusCellDataExists: true,
            statusSummaryTemplate: true,
            typeSummaryTemplate: "text",
            statusFormProc: false,
            noDataDisplay: false,
            typeColumn: "default",
            typeSortColumn: "text",
        },
        {
            name: "locationGroupName",
            nameParamUrl: "locationGroupName",
            label: "Location Group",
            width: 200,
            minWidth: undefined,
            maxWidth: undefined,
            headerClass: "text-left",
            cellClass: "text-left",
            statusCellDataExists: true,
            statusSummaryTemplate: true,
            typeSummaryTemplate: "text",
            statusFormProc: false,
            noDataDisplay: false,
            typeColumn: "default",
            typeSortColumn: "text",
        },
        {
            name: "locationName",
            nameParamUrl: "locationName",
            label: "Location",
            width: 220,
            minWidth: undefined,
            maxWidth: undefined,
            headerClass: "text-left",
            cellClass: "text-left",
            statusCellDataExists: true,
            statusSummaryTemplate: true,
            typeSummaryTemplate: "text",
            statusFormProc: false,
            noDataDisplay: false,
            typeColumn: "default",
            typeSortColumn: "text",
        },
        {
            name: "jobFamilyName",
            nameParamUrl: "jobFamilyName",
            label: "Job Family",
            width: 200,
            minWidth: undefined,
            maxWidth: undefined,
            headerClass: "text-left",
            cellClass: "text-left",
            statusCellDataExists: true,
            statusSummaryTemplate: true,
            typeSummaryTemplate: "text",
            statusFormProc: false,
            noDataDisplay: false,
            typeColumn: "default",
            typeSortColumn: "text",
        },
        {
            name: "jobName",
            nameParamUrl: "jobName",
            label: "Job",
            width: 150,
            minWidth: undefined,
            maxWidth: undefined,
            headerClass: "text-left",
            cellClass: "text-left",
            statusCellDataExists: true,
            statusSummaryTemplate: true,
            typeSummaryTemplate: "text",
            statusFormProc: false,
            noDataDisplay: false,
            typeColumn: "default",
            typeSortColumn: "text",
        },
        {
            name: "gradeName",
            nameParamUrl: "gradeName",
            label: "Grade",
            width: 120,
            minWidth: undefined,
            maxWidth: undefined,
            headerClass: "text-left",
            cellClass: "text-left",
            statusCellDataExists: true,
            statusSummaryTemplate: true,
            typeSummaryTemplate: "text",
            statusFormProc: false,
            noDataDisplay: false,
            typeColumn: "default",
            typeSortColumn: "text",
        },
        {
            name: "organizationLevelName",
            nameParamUrl: "organizationLevelName",
            label: "Organization Level",
            width: 200,
            minWidth: undefined,
            maxWidth: undefined,
            headerClass: "text-left",
            cellClass: "text-left",
            statusCellDataExists: true,
            statusSummaryTemplate: true,
            typeSummaryTemplate: "text",
            statusFormProc: false,
            noDataDisplay: false,
            typeColumn: "default",
            typeSortColumn: "text",
        },
        {
            name: "organizationName",
            nameParamUrl: "organizationName",
            label: "Organization",
            width: 180,
            minWidth: undefined,
            maxWidth: undefined,
            headerClass: "text-left",
            cellClass: "text-left",
            statusCellDataExists: true,
            statusSummaryTemplate: true,
            typeSummaryTemplate: "text",
            statusFormProc: false,
            noDataDisplay: false,
            typeColumn: "default",
            typeSortColumn: "text",
        },
        {
            name: "positionLevelName",
            nameParamUrl: "positionLevelName",
            label: "Position Level",
            width: 180,
            minWidth: undefined,
            maxWidth: undefined,
            headerClass: "text-left",
            cellClass: "text-left",
            statusCellDataExists: true,
            statusSummaryTemplate: true,
            typeSummaryTemplate: "text",
            statusFormProc: false,
            noDataDisplay: false,
            typeColumn: "default",
            typeSortColumn: "text",
        },
        {
            name: "positionName",
            nameParamUrl: "positionName",
            label: "Position",
            width: 150,
            minWidth: undefined,
            maxWidth: undefined,
            headerClass: "text-left",
            cellClass: "text-left",
            statusCellDataExists: true,
            statusSummaryTemplate: true,
            typeSummaryTemplate: "text",
            statusFormProc: false,
            noDataDisplay: false,
            typeColumn: "default",
            typeSortColumn: "text",
        },
        {
            name: "lengthOfServices",
            nameParamUrl: "lengthOfServices",
            label: "Length of Services",
            width: 220,
            minWidth: undefined,
            maxWidth: undefined,
            headerClass: "text-left",
            cellClass: "text-left",
            statusCellDataExists: true,
            statusSummaryTemplate: true,
            typeSummaryTemplate: "number",
            statusFormProc: false,
            noDataDisplay: false,
            typeColumn: "default",
            typeSortColumn: "number",
        },
        {
            name: "employmentType",
            nameParamUrl: "employmentType",
            label: "Employment Type",
            width: 180,
            minWidth: undefined,
            maxWidth: undefined,
            headerClass: "text-left",
            cellClass: "text-left",
            statusCellDataExists: true,
            statusSummaryTemplate: true,
            typeSummaryTemplate: "text",
            statusFormProc: false,
            noDataDisplay: false,
            typeColumn: "default",
            typeSortColumn: "text",
        },
        {
            name: "payrollGroupName",
            nameParamUrl: "payrollGroupName",
            label: "Payroll",
            width: 180,
            minWidth: undefined,
            maxWidth: undefined,
            headerClass: "text-left",
            cellClass: "text-left",
            statusCellDataExists: true,
            statusSummaryTemplate: true,
            typeSummaryTemplate: "text",
            statusFormProc: false,
            noDataDisplay: false,
            typeColumn: "default",
            typeSortColumn: "text",
        },
        {
            name: "activeLabel",
            nameParamUrl: "activeLabel",
            label: "Active",
            width: 140,
            minWidth: 140,
            maxWidth: 140,
            headerClass: "text-left",
            cellClass: "text-left",
            statusCellDataExists: true,
            statusSummaryTemplate: false,
            typeSummaryTemplate: null,
            statusFormProc: false,
            noDataDisplay: false,
            typeColumn: "default",
            typeSortColumn: "text",
        },
        {
            name: "action",
            nameParamUrl: "action",
            label: "Action",
            width: 150,
            minWidth: 150,
            maxWidth: 150,
            headerClass: "text-left",
            cellClass: "text-left",
            statusCellDataExists: true,
            statusSummaryTemplate: false,
            typeSummaryTemplate: null,
            statusFormProc: false,
            noDataDisplay: false,
            typeColumn: "edit_delete",
            typeSortColumn: "text",
            readOnly: false,
        },
    ];

    employeePointDataObj = {
        pointBackgroundColorLinear: null,
        pointCollectedPoint: null,
        pointCurrentLevel: null,
        pointCurrentPoint: null,
        pointHexBackgroundColor: null,
        pointLevelImage: null,
        pointLevelRangeStart: null,
        pointLevelRangeEnd: null,
        pointNextLevel: null,
    };

    constructor(private methodServices: MethodServices) {}

    // update AOD pada table filter
    changeAODParamFilter(effectiveDate, statusAOD, paramFirstLoad) {
        let gabungTemp: any;

        if (statusAOD) {
            // update dengan aod dengan tanggal baru

            if (this.arrFilterHeader.length > 0) {
                let findAOD = this.arrFilterHeader.findIndex((result) => {
                    // return result.toString().toLowerCase().indexOf("aod=") != -1
                    return result.toLowerCase().split("=")[0] + "=" == "aod=";
                });
                if (findAOD >= 0) {
                    this.arrFilterHeader.splice(findAOD, 1);
                }

                if (effectiveDate != "Invalid Date" && typeof effectiveDate != "undefined" && effectiveDate != null && effectiveDate != "") {
                    if (typeof effectiveDate == "string") {
                        effectiveDate = new Date(effectiveDate);
                    }

                    this.arrFilterHeader.push("aod=" + this.methodServices.formatDate(effectiveDate, "yyyy-mm-dd"));
                }

                if (this.arrFilterHeader.length > 0) {
                    let tempArrFilter = JSON.parse(JSON.stringify(this.arrFilterHeader));

                    if (tempArrFilter) {
                        tempArrFilter.forEach((data: any, index: number) => {
                            tempArrFilter[index] = data.replaceAll("&", "%26");
                        });
                    }
                    gabungTemp = tempArrFilter.join("&");
                } else {
                    gabungTemp = paramFirstLoad;
                }
            } else {
                // kemungkinan kondisi awal changeAOD
                gabungTemp = paramFirstLoad;
                this.triggerParamBlank.next(paramFirstLoad);
            }
        } else {
            if (this.gabung != "" && typeof this.gabung != "undefined" && this.gabung != null) {
                gabungTemp = this.gabung;
            } else {
                gabungTemp = paramFirstLoad;
            }
        }

        if (gabungTemp.substr(0, 1) == "?" || gabungTemp == "") {
            return gabungTemp;
        } else {
            return "?" + gabungTemp;
        }
    }

    getErrorMessageApi(result: any): any {
        let statusText: any;

        if (result["error"] == null) {
            if (typeof result["statusText"] != "undefined") {
                statusText = result["statusText"];
            }
        } else {
            if (typeof result["error"]["message"] != "undefined" && result["error"]["message"] != "" && result["error"]["message"] != null) {
                statusText = result["error"]["message"];
            } else if (typeof result["error"]["error"] != "undefined" && result["error"]["error"] != "" && result["error"]["error"] != null) {
                statusText = result["error"]["error"];
            } else {
                statusText = result["error"];
            }

            if (statusText == null || typeof statusText == "undefined") {
                if (typeof result["statusText"] != "undefined" && result["statusText"] != null) {
                    statusText = result["statusText"];
                }
            }
        }

        return statusText;
    }

    keypressValidate($event: any) {
        // $event.charCode -> get ascii (97,98,..)
        // $event.key -> get String (Enter, a, 1, ...)
        let charCode: any = $event.charCode; //101, 45

        if (charCode == 101 || charCode == 45 || ($event.target.value.length >= $event.target.maxLength && window.getSelection().toString() == "")) {
            return false;
        }
    }

    keypressValidateKoma($event: any) {
        // $event.charCode -> get ascii (97,98,..)
        // $event.key -> get String (Enter, a, 1, ...)

        let charCode: any = $event.charCode; //101, 45
        if (charCode == 101 || charCode == 45 || charCode == 46 || ($event.target.value.length >= $event.target.maxLength && window.getSelection().toString() == "")) {
            return false;
        }
    }

    keypressValidateTitik($event: any) {
        let charCode: any = $event.charCode;
        if (charCode == 101 || charCode == 44 || charCode == 46 || ($event.target.value.length >= $event.target.maxLength && window.getSelection().toString() == "")) {
            return false;
        }
    }

    keypressValidateTitikKoma($event: any) {
        // $event.charCode -> get ascii (97,98,..)
        // $event.key -> get String (Enter, a, 1, ...)

        let charCode: any = $event.charCode; //101, 45
        if (charCode == 101 || charCode == 44 || charCode == 45 || charCode == 46 || ($event.target.value.length >= $event.target.maxLength && window.getSelection().toString() == "")) {
            return false;
        }
    }

    keypressValidateCanMinusTitikKoma($event: any) {
        let charCode: any = $event.charCode; //101, 45
        if (charCode == 101 || charCode == 44 || charCode == 46 || ($event.target.value.length >= $event.target.maxLength && window.getSelection().toString() == "")) {
            return false;
        }
    }

    keypressValidateNumber($event: any) {
        let charCode: any = $event.charCode;
        if (!(charCode >= 48 && charCode <= 57)) {
            return false;
        }
    }

    keypressValidateDoubleBackSlash($event: any) {
        let key: any = $event.key;
        if (key == "\\") {
            return false;
        }
    }

    keypressValidateWithNumber($event: any) {
        let charCode: any = $event.charCode;
        if (charCode == 101 || charCode == 45 || !(charCode >= 48 && charCode <= 57) || ($event.target.value.length >= $event.target.maxLength && window.getSelection().toString() == "")) {
            return false;
        }
    }

    showTotalRecordFunc(result) {
        this.totalRecord = typeof result["totalRecord"] != "undefined" ? result["totalRecord"] : typeof result["totalElements"] != "undefined" ? result["totalElements"] : result.length;
        this.totalRecord_String =
            typeof result["totalRecord"] != "undefined"
                ? Number(result["totalRecord"]).toLocaleString("id-ID", { style: "decimal" })
                : typeof result["totalElements"] != "undefined"
                ? Number(result["totalElements"]).toLocaleString("id-ID", { style: "decimal" })
                : Number(result.length).toLocaleString("id-ID", { style: "decimal" });

        this.totalShowRecord = typeof result["totalShowRecord"] != "undefined" ? result["totalShowRecord"] : typeof result["totalElements"] != "undefined" ? result["totalElements"] : result.length;
        if ((typeof result["totalRecord"] != "undefined" && typeof result["totalShowRecord"] != "undefined") || typeof result["totalElements"] != "undefined" || result.length) {
            this.totalSelisihRecord = this.totalRecord - this.totalShowRecord;

            if (result["totalRecord"] > 1000) {
                this.footerHeight = 100;
            } else {
                this.footerHeight = 50;
            }
        } else {
            this.footerHeight = 50;
        }
    }

    validateGeo(event: any, valGeo: any) {
        var valGeoObj = document.getElementById(valGeo) as HTMLInputElement;
        var valGeoStart = valGeoObj.selectionStart;
        var val = event.target.value;

        if (event.keyCode == 189) {
            // -
            if (val.indexOf("-") != -1) {
                return false;
            }
        }
        if (event.keyCode == 190) {
            // .
            if (val.indexOf(".") != -1) {
                return false;
            }
        }

        // cek jika hapus titik, maka cek length nya kembali (jangan melebihi maxlength)

        if (event.keyCode == 8) {
            //backspace
            var posisiTitik: any = val.indexOf(".");
            if (valGeoStart > 1) {
                if (val.substr(valGeoStart - 1, 1) == ".") {
                    var hapusTitik = val.replace(".", "").replace("-", "");
                    if (hapusTitik.length > this.sc_geolocation_sign) {
                        return false;
                    }
                }
            }
        }

        if (event.keyCode == 46) {
            //delete
            var posisiTitik: any = val.indexOf(".");
            if (valGeoStart >= 0) {
                if (val.substr(valGeoStart, 1) == ".") {
                    var hapusTitik = val.replace(".", "").replace("-", "");
                    if (hapusTitik.length > this.sc_geolocation_sign) {
                        return false;
                    } else {
                        return true;
                    }
                } else {
                    return true;
                }
            }
        }

        // Cek maxlength Sebelum Decimal
        var posisiTitik: any = val.indexOf(".");
        var lengthSebTitik: any = val.substr(0, posisiTitik).replace("-", "");

        if (event.keyCode >= 48 && event.keyCode <= 57 && window.getSelection().toString() == "") {
            if (posisiTitik != -1) {
                if (valGeoStart <= posisiTitik) {
                    if (lengthSebTitik.length >= this.sc_geolocation_sign) {
                        return false;
                    }
                } else {
                    // Cek maxlength Setelah Decimal
                    var valAfterDec = val.substr(posisiTitik + 1, val.length).replace("-", "");
                    if (valAfterDec.length >= this.sc_geolocation_dec) {
                        return false;
                    }
                }
            } else {
                if (val.replace("-", "").length >= this.sc_geolocation_sign) {
                    return false;
                }
            }
        }

        if (
            // 36 -> Home
            // 35 -> end
            // 189 -> -
            // 190 -> .
            (event.keyCode >= 48 && event.keyCode <= 57) ||
            event.keyCode == 8 ||
            event.keyCode == 9 ||
            event.keyCode == 13 ||
            event.keyCode == 189 ||
            event.keyCode == 190 ||
            event.keyCode == 37 ||
            event.keyCode == 38 ||
            event.keyCode == 39 ||
            event.keyCode == 40 ||
            event.keyCode == 36 ||
            event.keyCode == 35
        ) {
            return true;
        } else {
            return false;
        }
    }

    checkIsTime(data: any, includeSeconds?: boolean) {
        let regexp = /^([01]?[0-9]|2[0-3]):([0-5]?[0-9])$/;
        if (regexp.test(data)) {
            return true;
        } else {
            return false;
        }
    }

    // google organization chart
    // fixing ketidak sesuian width antar node dengan jumlah genap
    fixingNodeOrgChart() {
        let node_Int = setInterval(() => {
            let node_selector = document.querySelectorAll("tr.google-visualization-orgchart-noderow-medium");

            if (node_selector.length > 0) {
                setTimeout(() => {
                    node_selector.forEach((element) => {
                        let nodeMedium = element.querySelectorAll("td.google-visualization-orgchart-node.google-visualization-orgchart-node-medium");
                        if (nodeMedium.length > 1 && nodeMedium.length % 2 == 0) {
                            // harus yang lebih dari satu length & habis dibagi 2 (angka genap)
                            // nodeMedium dikurangi 1, lalu bagi 2 (misal (6-1)/2 =>5/2 =>2.5 =>2 )
                            let nodeSel = Math.floor((nodeMedium.length - 1) / 2);
                            nodeMedium[nodeSel].setAttribute("colspan", "5"); // google-visualization-orgchart-nodesel (kiri)
                            nodeMedium[nodeSel].nextElementSibling.setAttribute("colspan", "4"); // google-visualization-orgchart-linenode (gap diantara 2 node)
                            nodeMedium[nodeSel].nextElementSibling.nextElementSibling.setAttribute("colspan", "5"); // google-visualization-orgchart-node-medium (kanan)

                            // revisi padding left & right di antara dua node
                            if (nodeMedium.length > 2) {
                                let conrowMedium = document.querySelectorAll(".google-visualization-orgchart-connrow-medium");
                                conrowMedium.forEach((elementConRow) => {
                                    let lineLeft = elementConRow.querySelectorAll(".google-visualization-orgchart-lineleft");
                                    if (lineLeft.length > 2) {
                                        (<HTMLElement>lineLeft[nodeSel]).style.paddingLeft = "4px";
                                    }
                                    let lineRight = elementConRow.querySelectorAll(".google-visualization-orgchart-lineright");
                                    if (lineRight.length > 2) {
                                        (<HTMLElement>lineRight[0]).style.paddingRight = "4px";
                                    }
                                });
                            }
                        }
                    });

                    // geser line node dengan syarat node paling bawah berjumlah satu node saja
                    // cek terlebih dahulu apakah node paling bawah berjumlah satu atau tidak
                    let node_selector_bottom = node_selector[node_selector.length - 1];
                    let node_selector_bottom_child = node_selector_bottom.querySelectorAll("td.google-visualization-orgchart-node.google-visualization-orgchart-node-medium");

                    if (node_selector_bottom_child.length == 1) {
                        let line_connrowMedium = document.querySelectorAll("tr.google-visualization-orgchart-connrow-medium");
                        line_connrowMedium.forEach((ele) => {
                            // cukup update element child pertama saja, karena line connrow yang lain akan mengikuti
                            (<HTMLElement>line_connrowMedium[0].firstElementChild).style.paddingRight = "22px";
                        });
                    }

                    clearInterval(node_Int);
                }, 150);
            }
        });
    }

    // untuk RANDOM NUMBER berdasarkan nilai awal dan akhir
    range_RandomNumber(min: number, max: number): number {
        let random = Math.floor(Math.random() * (max - min + 1)) + min;
        return random;
    }

    set_ReadOnly_dataInput(name: any[] | "all", dataInput: any[], readOnly: boolean): any {
        // name -> key "name" dari data input yang akan di set readonly harus berupa array  (e.g. ['action'])
        // dataInput -> array dataInput (konfigurasi table global)

        let temp_dataInput = JSON.parse(JSON.stringify(dataInput));

        if (typeof name == "undefined" || name == null || name.length == 0 || typeof dataInput == "undefined" || dataInput == null || dataInput.length == 0) {
            this.methodServices.showToast("!!!FOR TECHNICAL Check Parameter name / dataInput", "warning");
            return;
        }

        if (Array.isArray(name)) {
            if (name.length > 0) {
                // ['action', dst...]
                name.forEach((val, idx) => {
                    let findIdx_dataInput = temp_dataInput.findIndex((dtInput) => dtInput?.["name"].toLowerCase() == val.toLowerCase());

                    if (findIdx_dataInput != -1) {
                        temp_dataInput[findIdx_dataInput]["readOnly"] = readOnly;

                        if (typeof temp_dataInput[findIdx_dataInput]?.["typeColumn"] != "undefined" && (temp_dataInput[findIdx_dataInput]?.["typeColumn"] == "flexible_action" || temp_dataInput[findIdx_dataInput]?.["typeColumn"] == "flexible_action_disabled")) {
                            if (readOnly) {
                                temp_dataInput[findIdx_dataInput]["typeColumn"] = "flexible_action_disabled";
                            } else {
                                temp_dataInput[findIdx_dataInput]["typeColumn"] = "flexible_action";
                            }
                        }
                    }
                });
            }
        } else {
            // jika 'all' key name
            for (let t_dataInput of temp_dataInput) {
                t_dataInput["readOnly"] = readOnly;

                if (typeof t_dataInput?.["typeColumn"] != "undefined" && (t_dataInput?.["typeColumn"] == "flexible_action" || t_dataInput?.["typeColumn"] == "flexible_action_disabled")) {
                    if (readOnly) {
                        t_dataInput["typeColumn"] = "flexible_action_disabled";
                    } else {
                        t_dataInput["typeColumn"] = "flexible_action";
                    }
                }
            }
        }

        return JSON.parse(JSON.stringify(temp_dataInput));
    }

    update_to_null_arrobj(arrobj: any) {
        // UPDATE SEMUA STRING YANG ('') DIJADIKAN NULL

        Object.entries(arrobj).forEach(([k, v]) => {
            if (v instanceof Object) {
                this.update_to_null_arrobj(v);
            }
            if (typeof v == "string" && v == "") {
                arrobj[k] = null;
            }
        });
        return arrobj;
    }

    grouping_array(keyId: any, obj: any[]) {
        // SAMPLE DEPLOY
        // let tes = [
        //   {mppId:'123',mppName:'satu dua tiga'},
        //   {mppId:'123',mppName:'satu dua tiga'},
        //   {mppId:'tes',mppName:'tes halo'},
        //   {mppId:'tes',mppName:'tes halo'},
        //   {mppId:'tes',mppName:'tes halo'},
        //   {mppId:'123',mppName:'satu dua tiga'}
        // ]
        // let newArr:any[] = this.dataTypeServices.grouping_array('mppId',tes);    // array object
        // let newArr2:any[] = this.dataTypeServices.grouping_array(null,tes2);     // array tanpa object (e.g. [1,2,3])
        // ... end SAMPLE DEPLOY

        let obj_temp = JSON.parse(JSON.stringify(obj)); // data array dari parameter
        let obj_new: any[] = [];

        if (obj_temp.length > 0) {
            obj_temp.forEach((element) => {
                let valid_push: boolean = false;

                if (keyId == null) {
                    // keyId == null, hanya jika data array tanpa object (e.g. [1,2,3,3])
                    let find_in_objnew = obj_new.find((row) => row === element);

                    if (!find_in_objnew) {
                        valid_push = true;
                    } else {
                        valid_push = false;
                    }
                } else {
                    if (typeof element?.[keyId] != "undefined") {
                        if (obj_new.length > 0) {
                            let find_in_objnew = obj_new.find((row) => row?.[keyId] == element?.[keyId]);
                            if (!find_in_objnew) {
                                valid_push = true;
                            } else {
                                valid_push = false;
                            }
                        } else {
                            valid_push = true;
                        }
                    } else {
                        valid_push = true;
                    }
                }

                // push ke array jika memang data unik
                if (valid_push) {
                    if (element == null) {
                        // null / undefined
                        obj_new.push(null);
                    } else if (Array.isArray(element)) {
                        // array
                        obj_new.push([...element]);
                    } else if (typeof element == "object") {
                        // object
                        obj_new.push({ ...element });
                    } else {
                        obj_new.push(element); // string, number, etc...
                    }
                }
            });
            return JSON.parse(JSON.stringify(obj_new));
        } else {
            return obj_new;
        }
    }

    // GENERATE FORMAT API
    generate_Format_API(obj: any, attribute: generate_Format_API_attr, replace_null?: any, arr_hardcode?: { arr_key: any; obj_data: any }, arr_indexRows?: { arr_key: any; default_value: number | 0 }, local_trigger_arr?: any, local_trigger_indexRows?: any) {
        // INITIATED DI AWAL
        if (typeof arr_indexRows != "undefined") {
            let arr_indexRows_temp: any = arr_indexRows?.["default_value"] ?? 0;
            arr_indexRows["default_value"] = --arr_indexRows_temp;
        }

        this.generate_Format_API_Final(obj, attribute, replace_null, arr_hardcode, arr_indexRows);

        return obj;
    }

    generate_Format_API_Final(obj: any, attribute: generate_Format_API_attr, replace_null?: any, arr_hardcode?: { arr_key: any; obj_data: any }, arr_indexRows?: { arr_key: any; default_value: number | 0 }, local_trigger_arr?: any, local_trigger_indexRows?: any) {
        // local_trigger_arr => hanya untuk function local, tidak diparsing dari luar

        // try {
        Object.entries(obj).forEach(([k, v]) => {
            if (typeof v == "object" && v != null) {
                if (Array.isArray(v)) {
                    // HARDCODE KEY-VALUE
                    if (typeof arr_hardcode != "undefined" && arr_hardcode != null) {
                        if (typeof arr_hardcode?.["arr_key"] != "undefined") {
                            if (arr_hardcode?.["arr_key"] == k) {
                                local_trigger_arr = "valid";
                            } else {
                                local_trigger_arr = "invalid";
                            }
                        } else {
                            local_trigger_arr = "valid";
                        }
                    } else {
                        local_trigger_arr = "invalid";
                    }

                    // INDEX ROWS
                    if (typeof arr_indexRows != "undefined") {
                        if (typeof arr_indexRows?.["arr_key"] != "undefined") {
                            if (arr_indexRows?.["arr_key"] == k) {
                                local_trigger_indexRows = "valid";
                            } else {
                                local_trigger_indexRows = "invalid";
                            }
                        } else {
                            local_trigger_indexRows = "valid";
                        }
                    } else {
                        local_trigger_indexRows = "invalid";
                    }
                } else {
                    // JIKA OBJECT BUKAN ARRAY, maka increment satu angka pada default value
                    if (local_trigger_indexRows == "valid") {
                        let default_value: any = arr_indexRows?.["default_value"] ?? 0;
                        default_value += 1;
                        arr_indexRows["default_value"] = default_value;
                    }
                }

                this.generate_Format_API_Final(v, attribute, replace_null, arr_hardcode, arr_indexRows, local_trigger_arr, local_trigger_indexRows);
            } else {
                // KONDISI DATE
                if (typeof attribute?.["date"] != "undefined") {
                    let _find_date = attribute?.["date"].find((row) => row == k);
                    if (_find_date) {
                        if (v == null) {
                            if (typeof replace_null != "undefined") {
                                obj[k + "CstYyyymmdd1"] = replace_null;
                                obj[k + "CstYyyymmdd1_Sort"] = replace_null;
                                obj[k + "CstDdmmmyyyy"] = replace_null;
                                obj[k + "CstDdmmmyyyy_Sort"] = replace_null;
                            } else {
                                obj[k + "CstYyyymmdd1"] = v;
                                obj[k + "CstYyyymmdd1_Sort"] = v;
                                obj[k + "CstDdmmmyyyy"] = v;
                                obj[k + "CstDdmmmyyyy_Sort"] = v;
                            }
                        }

                        if (typeof v == "number") {
                            if (!Array.isArray(obj)) {
                                obj[k + "CstYyyymmdd1"] = this.methodServices.formatDate(new Date(v), "yyyy-mm-dd");
                                obj[k + "CstYyyymmdd1_Sort"] = parseInt(this.methodServices.formatDate(new Date(v), "yyyymmdd"));
                                obj[k + "CstDdmmmyyyy_Sort"] = parseInt(this.methodServices.formatDate(new Date(v), "yyyymmdd"));
                                obj[k + "CstDdmmmyyyy"] = this.methodServices.formatDate(new Date(v), "dd-mmm-yyyy");
                            }
                        }
                    }
                }

                // KONDISI CURRENCY
                if (typeof attribute?.["currency"] != "undefined") {
                    let _find_currency = attribute?.["currency"].find((row) => row == k);
                    if (_find_currency) {
                        if (v == null) {
                            if (typeof replace_null != "undefined") {
                                obj[k + "CstCurrency"] = replace_null;
                            } else {
                                obj[k + "CstCurrency"] = v;
                            }
                        }

                        if (typeof v == "number") {
                            if (!Array.isArray(obj)) {
                                obj[k + "CstCurrency"] = this.methodServices.formatNumber(v, "decimal_2");
                            }
                        }
                    }
                }

                // KONDISI BOOLEAN
                if (typeof attribute?.["boolean"] != "undefined") {
                    let _find_boolean = attribute?.["boolean"].find((row) => row?.["key"] == k);
                    if (_find_boolean) {
                        if (v == null) {
                            if (typeof replace_null != "undefined") {
                                obj[k + "CstBool"] = replace_null;
                            } else {
                                obj[k + "CstBool"] = v;
                            }
                        }

                        if (typeof v == "boolean") {
                            if (!Array.isArray(obj)) {
                                obj[k + "CstBool"] = v ? _find_boolean?.["value_true"] : _find_boolean?.["value_false"];
                            }
                        }
                    }
                }

                // KHUSUS REPLACE NILAI NULL DENGAN PARAMETER replace_null
                if (v == null) {
                    if (typeof replace_null != "undefined") {
                        obj[k] = replace_null;
                    } else {
                        obj[k] = v;
                    }
                }

                if (local_trigger_arr == "valid") {
                    if (obj instanceof Object && !Array.isArray(obj)) {
                        for (let key of Object.keys(arr_hardcode?.["obj_data"])) {
                            obj[key] = arr_hardcode?.["obj_data"]?.[key] ?? "";
                        }
                    }
                }

                // KHUSUS generate indexRows
                if (local_trigger_indexRows == "valid") {
                    if (obj instanceof Object && !Array.isArray(obj)) {
                        if (typeof obj?.["indexRows"] == "undefined") {
                            obj["indexRows"] = arr_indexRows?.["default_value"];
                        }
                    }
                }
            }
        });
        return obj;
    }
}
